import { Link } from 'gatsby';
import React from 'react';
import Accordion from '../components/Accordion/Accordion';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const privacyPolicyFaq = [
  {
    question: 'About Aeldra',
    answer: (
      <p>
        Aeldra is a digital bank* serving students, professionals, and investors
        from Asia in meeting their banking* needs in the US. Aeldra will assist
        individuals with US banking* needs for employment, educational or
        investment purposes to establish a bank account* prior to obtaining US
        taxpayer identification. Aeldra will operate through its US bank partner
        and will be compliant with regulatory obligations, supervisory
        expectations, and industry practices. Aeldra cares about your privacy
        and wants you to be familiar with how we collect, use and disclose data.
        This Privacy Statement covers the collection, use and disclosure on this
        Site where the statement is linked.
      </p>
    ),
  },
  {
    question: 'What general data is collected?',
    answer: (
      <>
        <p>
          Personal Information. Generally, “Personal Information” refers to any
          piece or combination of information that identifies, relates to,
          describes, or can reasonably be linked or associated with you or your
          household. Personal Information includes, but is not limited to any
          means of identification, whether traditional, digital, or other
          documentary information.{' '}
        </p>

        <p> Traditional identifiers include:</p>
        <ul>
          <li> Name</li>
          <li> Date of birth</li>
          <li> Personal identification number</li>
          <li> Physical address</li>
          <li> Telephone number</li>
        </ul>

        <p>Digital identifiers include:</p>
        <ul>
          <li> Device ID</li>
          <li> Internet Protocol (IP) address</li>
          <li> Cookies</li>
          <li> Platform or website that sent you to Aeldra</li>
          <li> Voice recordings when you contact Aeldra</li>
        </ul>

        <p>Other information includes:</p>
        <ul>
          <li> Government-issued photo ID</li>
          <li> Photographs of you</li>
          <li>
            {' '}
            Proof of address documentation, such as a lease or rental agreement
          </li>
          <li> Proof of external bank account*</li>
          <li> Occupational information</li>
        </ul>

        <p>
          Personal Information also includes any information associated with
          your identity. This might include any information about how you use a
          Aeldra website; where you’re located when you login; what operating
          system you use; what Aeldra products and services you use; your credit
          score; and your financial habits. Anything about you is your Personal
          Information.
        </p>
        <p>
          Data you give us. Aeldra collects data you provide us in a variety of
          ways, such as when you submit questions, request information, or give
          us feedback, we may ask you for Personal Information. We may also ask
          you to provide other data relevant to your question, request, or
          feedback, such as your geographical location, professional and
          demographic information, industry, and preferred contact method.
        </p>
        <p>
          In the course of your use of the Services, you may correct or update
          your information collected at enrollment.
        </p>
      </>
    ),
  },
  {
    question: 'Why we collect this information?',
    answer: (
      <ul>
        <li>
          Aeldra collects your identifiers in order to provide the Services and
          the following:
        </li>
        <li>
          Analyze individual and aggregated behavior to improve and customize
          the Services
        </li>
        <li>
          Verify your identity and comply with our bank partners’ compliance
          with applicable law, including anti-money laundering (AML) and
          sanctions screening rules designed to prevent, detect, and investigate
          fraud, hacking, infringement, or other suspected or actual misconduct,
          crime, or violation of an agreement involving the Services
        </li>
        <li>
          Send you announcements, newsletters, promotional materials, and other
          information about the Services and third-party products and services
          that we think may be of interest to you
        </li>
        <li>
          Collect fees and other amounts owed in connection with the Services
        </li>
        <li>Comply with obligations to tax authorities</li>
        <li>Diagnose and debug our technical systems</li>
      </ul>
    ),
  },
  {
    question: 'How we use the data?',
    answer: (
      <>
        <p>
          {' '}
          Coordinating With Business Partners. In order to accomplish the
          business purposes described above, Aeldra may disclose your
          traditional and digital identifiers to the following categories of
          third-party service providers: our bank partners, payment processors,
          payment networks, card fulfillment vendors, mobile check deposit
          processing services, identity services, customer service vendors,
          communications platforms, risk investigation and mitigation tools,
          transaction dispute processing services, website and app usage
          trackers, data processors, modeling and analytics tools, and network
          infrastructure and data storage services. These third-party service
          providers are contractually obligated to secure and refrain from
          disclosing any Personal Information which we disclose or otherwise
          entrust to them.
        </p>

        <p>
          Aeldra may use certain transaction information as applicable to
          determine your eligibility for certain features or elements of the
          Services. If you are referred to Aeldra by certain marketing partners,
          Aeldra may use your this information to determine what information it
          must collect from you and whether the partner must be compensated for
          referring you. Additionally, Aeldra collects this information for the
          purposes applicable to financial information generally.
        </p>
        <p>
          History of Aeldra partner bank account* transactions. Aeldra collects
          your Aeldra account* transaction history from its processor and bank
          partner. It collects this information in order to provide you with the
          Services, in particular preparing your statement, resolving errors and
          unauthorized transfers, and verifying your identity in certain
          circumstances.
        </p>
        <p>
          Credit reporting and scoring information. If you enroll in certain
          features of the Services, Aeldra may collect credit score and other
          credit history data from a credit bureau. Aeldra collects this
          information in order to provide you with the Services, in particular
          to surface your credit score in the app.
        </p>
        <p>
          Direct deposit information. If you enroll in Aeldra direct deposit,
          Aeldra will collect identifying information about your employer from
          you or a third-party service provider, including your employer’s name
          and address. Aeldra may use this information to analyze trends in its
          user base. Aeldra will not share information about your employer
          unless it is de-identified and aggregated. Additionally, Aeldra
          collects this information for the purposes applicable to employment
          information generally (see below).
        </p>
        <p>
          Commercial Information. Commercial information consists of information
          about your interest in a particular product or service, your
          purchasing or consuming tendencies, and/or receipts or records for
          products or services.
        </p>
        <p>Aeldra collects this information from you whenever:</p>
        <ul>
          <li>
            You begin the enrollment flow or click on an email ad for a new
            Aeldra product;
          </li>
          <li>
            You make a purchase with your Aeldra debit^ or credit card; or
          </li>
          <li>
            You produce a receipt in response to a fraud prevention, anti-money
            laundering, or security inquiry from Aeldra.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'Who we share this information with?',
    answer: (
      <>
        <p>
          In connection with one or more of the purposes outlined above, we may
          disclose your information to:
        </p>
        <ul>
          <li>
            Complete a transaction you request or authorize, or provide you with
            a product or service;
          </li>
          <li>
            Third parties that provide services to us, such as system hosting,
            management, and support; data analysis; data backup; and security
            and storage services;
          </li>
          <li>
            As otherwise outlined in this Statement. For example, to advertise
            to you on social media platforms pseudonymous data is provided to
            the social media platform;
          </li>
          <li>
            Relevant third parties as part of a corporate transaction, such as a
            reorganization, merger, sale, joint venture, assignment, transfer,
            or other disposition of all or any portion of our business, assets,
            or stock (including in connection with a bankruptcy or similar
            proceeding);
          </li>
          <li>
            Competent governmental and public authorities, in each case to
            comply with legal or regulatory obligations or requests; and
          </li>
          <li>
            Other third parties as we believe necessary or appropriate: (a)
            under applicable law; (b) to comply with legal process; (c) to
            enforce our terms and conditions; (d) to protect our operations and
            those of any of our affiliates; (e) to protect our rights, privacy,
            safety, or property, and/or those of our affiliates, you or others;
            and (f) to allow us to pursue available remedies or limit damages
            that we may sustain.
          </li>
          <li>
            In general, Aeldra discloses each category of Personal Information
            for the same purpose it is collected. In addition, Aeldra may
            disclose certain categories of your Personal Information in the
            circumstances described below:
          </li>
          <li>
            Legal disclosures. Aeldra may transfer and disclose information as
            applicable, including your Personal Information, information about
            how the Services are accessed and used, to third parties to comply
            with a legal obligation; when we believe in good faith that the law
            requires it; at the request of governmental authorities conducting
            an investigation; to verify or enforce our User Agreement or other
            applicable policies; to respond to an emergency; or otherwise to
            protect the rights, property, safety or security of third parties,
            users of the Services, or the public.
          </li>
          <li>
            Business transfers. As we continue to develop our business, we may
            sell or purchase assets. If another entity acquires us or our
            assets, Personal Information collected through the Services may be
            disclosed to such entity as one of the transferred assets. Also, if
            any bankruptcy or reorganization proceeding is brought by or against
            us, all such information may be considered an asset of ours and as
            such may be sold or transferred to third parties.
          </li>
          <li>
            With your permission. At your direction or request, we may share
            your Personal Information with specified third parties.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'Third-party sites',
    answer: (
      <>
        <p>
          This Privacy Statement does not address, and we are not responsible
          for, the privacy practices of any third parties, including those that
          operate sites to which this site links. Including a link on our site
          does not imply that we or our affiliates endorse the practices of the
          linked site.
        </p>

        <p>
          When you access or use the Services, you may be directed to other
          websites or applications that are beyond our control. We may also
          allow third-party websites or applications to link to the Services or
          use Session Trackers with the Services. We are not responsible for the
          privacy practices of any third parties or the content of linked
          websites and applications. We encourage you to read the applicable
          privacy policies and terms and conditions of such parties, websites,
          and applications. This Privacy Policy only applies to the Services.
        </p>
      </>
    ),
  },
  {
    question: 'Use of site by children',
    answer: (
      <p>
        Aeldra understands the importance of protecting the privacy of minors,
        especially in the online environment. This Site is not designed for or
        intentionally targeted at minors under the age of 18. It is our policy
        to never knowingly collect or maintain personal information about anyone
        under the age of 18. If we become aware that we have collected personal
        information relating to a minor, we will take reasonable steps to delete
        it.
      </p>
    ),
  },
  {
    question: 'Security',
    answer: (
      <p>
        We have in place reasonable organizational, technical, and
        administrative measures that are designed to protect data from loss,
        misuse, and unauthorized access, disclosure, alteration, destruction,
        and other forms of unlawful processing while it is under our control.
        However, please be aware that the storage and transfer of data cannot
        always be one-hundred percent secure. You also play a role in protecting
        your Personal Information. Please safeguard your username and password
        for your Account* and do not share them with others. If we receive
        instructions using your Account* log-in information, we will consider
        that you have authorized the instructions. You agree to notify us
        immediately of any unauthorized use of your Account* or any other breach
        of security related to the Services. We reserve the right, in our sole
        discretion, to refuse to provide the Services, terminate your Account*,
        and to remove or edit content.
      </p>
    ),
  },
  {
    question: 'Retention period',
    answer: (
      <p>
        We will retain data about you as long as necessary for the fulfillment
        of the purposes set out in this Privacy Statement, unless applicable law
        or professional obligations requires a longer retention period.
      </p>
    ),
  },
  {
    question: 'Special notice to California residents',
    answer: (
      <p>
        If you are a resident of the state of California, you are entitled to
        request information regarding the disclosure of personal information
        about you to third parties for direct marketing purposes. Please note
        that we do not share personal information about you with non-affiliated
        third parties for such purposes.
      </p>
    ),
  },
  {
    question: 'Cookies and other similar technologies',
    answer: (
      <>
        <p>
          {' '}
          Overview: Our Site uses cookies and other similar technology. Cookies
          are text files that web servers send to your computer for record
          keeping and other purposes. The Site may use session cookies (which
          expire when you close your browser) and persistent cookies (that
          remain on your computer after you close your browser until you delete
          them or they expire. We use cookies for security purposes, to help you
          navigate the Site, to display information more effectively, and to
          better serve you with tailored information on the Site. We also use
          cookies to gather statistical information about how visitors use the
          Site in order to continually improve its design and function.
        </p>

        <p>
          Pixel tags, web beacons, clear GIFs, and other similar technologies
          are used in connection with some Site pages to, among other things,
          track the actions of Site users, compile statistics about Site usage,
          associate non-identifiable demographic and preference data with
          device-related data (e.g., cookie IDs), and in some cases matching
          that with similar data supplied by other companies or estimate the age
          ranges of the audience that viewed the videos on this Site. We use,
          and permit third parties to use, this technology that recognize a
          unique identifier from a cookie placed on your device, and to provide
          content and ads that are more relevant to you. We also use this
          technology to compile information about your interaction with email or
          other communications and determine whether our communications have
          been opened.
        </p>
        <p>
          If you do not want information collected through the use of cookies,
          there is a simple procedure in most browsers that allows you to
          decline the use of cookies. To learn more how to manage cookies,
          please visit{' '}
          <a href="http://www.allaboutcookies.org/manage-cookies/">
            http://www.allaboutcookies.org/manage-cookies/
          </a>
          . Some features of the Site may not work properly if you decline the
          use of cookies.
        </p>
        <p>
          Third-Parties: The Site contains cookies or similar technologies from
          third-party providers that help us understand how visitors access our
          Site, use our Site, and how the site is performing. This information
          is used to improve our Site and to make our Site more intuitive. These
          technologies enable the third-party providers to set or read their own
          cookies or other identifiers on your device.
        </p>
      </>
    ),
  },
  {
    question: 'Honoring do-not-track browser signals',
    answer: (
      <p>
        “Do Not Track” (“DNT”) is a preference you can set in your web browser
        to let the websites you visit know that you do not want them collecting
        information about you. At this time, this Site does not respond to DNT
        or similar signals.
      </p>
    ),
  },
  {
    question: 'Updates to this privacy statement',
    answer: (
      <>
        <p>
          We may update and make changes to this Privacy Statement from time to
          time in light of, for example, changing business or information
          handling practices, technology, or legal requirements. When we make
          changes to this Privacy Statement, we will amend the “Last Revised”
          date at the top of this page, and such modified or amended version of
          this Privacy Statement shall be effective as to you and your
          information as of that revision date. We encourage you to review this
          Privacy Statement periodically to be informed about how we are
          protecting your information.
        </p>

        <p>
          Aeldra’s Product Agreement can be found on our website at this{' '}
          <Link to="/product-agreement">link</Link>.
        </p>
      </>
    ),
  },
].map(({ question, answer }, id) => ({
  question,
  answer,
  id,
}));

const privacyolicy = () => (
  <Layout>
    <SEO
      title="Privacy Page | Aeldra"
      description="This page is to inform app visitors regarding Aeldra's policies with the collection, use, and disclosure of Personal Information if anyone decided to use the services of Aeldra."
    />

    <div className="no-hero-layout">
      <div className="container">
        <div className="row">
          <div className="col-md col-8 offset-2">
            <h1>Privacy policy</h1>

            <div className="terms-content">
              <Accordion data={privacyPolicyFaq} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default privacyolicy;
